export default async function entryCsv(clubData: any, entries: any) {
  let rows: any[] = [];
  let headers: any[] = entries.map((entry: any) => entry.abbr);
  const order = ["IMP", "NA", "EA", "INF", "PER", "PR", "HI"];

  headers = ["First", "Last", "Club"];
  let duo = false;
  entries
    .filter((entry: any) => entry.abbr.startsWith("JR"))
    .forEach((entry: any) => headers.push(entry.abbr));
  entries
    .filter((entry: any) => entry.abbr.startsWith("JV"))
    .forEach((entry: any) => headers.push(entry.abbr));
  entries
    .filter((entry: any) => {
      return !(
        entry.abbr.startsWith("JV") ||
        entry.abbr.startsWith("JR") ||
        entry.name.endsWith("Debate")
      );
    })
    .sort((_a: any, _b: any) => {
      const a = _a.abbr;
      const b = _b.abbr;

      const extractBaseAndSuffix = (item: any) => {
        for (const base of order) {
          if (item.startsWith(base)) {
            const remainder = item.slice(base.length);
            if (remainder === "A" || remainder === "B") {
              return [base, remainder];
            }
            return [base, ""];
          }
        }
        return [item, ""];
      };

      const [baseA, suffixA] = extractBaseAndSuffix(a);
      const [baseB, suffixB] = extractBaseAndSuffix(b);

      const indexA = order.indexOf(baseA);
      const indexB = order.indexOf(baseB);

      if (indexA !== indexB) {
        return indexA - indexB;
      }

      if (suffixA === "A" && suffixB === "B") return -1;
      if (suffixA === "B" && suffixB === "A") return 1;
      if (suffixA === "A" && suffixB === "") return -1;
      if (suffixA === "B" && suffixB === "") return -1;
      if (suffixB === "A" && suffixA === "") return 1;
      if (suffixB === "B" && suffixA === "") return 1;

      return 0;
    })
    .forEach((entry: any) => {
      if (entry.name.toLowerCase().includes("duo")) {
        duo = true;
      }
      headers.push(entry.abbr)
    });

  entries
    .filter((entry: any) => entry.name.endsWith("Debate"))
    .forEach((entry: any) => headers.push(entry.abbr));

  headers.push("Debate Partner");
  if (duo) {
    headers.push("Duo Partner");
  }
  rows.push(headers);
  clubData.map((club: any) => {
    return club.families.map((family: any) => {
      family.students.map((student: any) => {
        let stu = Array(headers.length).fill("");
        student.events.map((event: any) => {
          const hIndex = headers.findIndex((entry: string) => {
            return entry === event.eventAbbr;
          });
          rows.push();

          stu[hIndex] = "x";
        });
        stu[0] = student.first;
        stu[1] = student.last;
        stu[2] = club.name;
        if (student.partners.debate.length > 0) {
          stu[headers.length - 2] = student.partners.debate;
        }
        if (duo && student.partners.duo.length > 0) {
          stu[headers.length - 1] = student.partners.duo;
        }
        rows.push(stu);
      });
    });
  });

  let csvData = rows.join("\n");

  const blob = new Blob([csvData], { type: "text/csv" });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement("a");

  // Passing the blob downloading url
  a.setAttribute("href", url);

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute("download", "CCATournamentEntries.csv");

  // Performing a download with click
  a.click();
}
