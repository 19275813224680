import _ from "lodash";
import { EVENT_CONST } from "./feeVals";
import calcFee from "./calcFee";
import speechAndDebate from "./speechAndDebate";
import entryCsv from "./generateCsvEntries";
import { findNestedObj } from "./genLabels";
export default async function parseFile(
  file: any,
  isSeperate: boolean,
  debatePoolName: string,
  iePoolName: string,
  handleClickOpen: () => void
) {
  const fileReader: any = new FileReader();
  fileReader.readAsText(file, "UTF-8");
  var res;
  let jsonData: any;
  await new Promise<void>((resolve) => (fileReader.onload = () => resolve()));
  try {
    jsonData = JSON.parse(fileReader.result);
  } catch {
    handleClickOpen();
    return;
  }
  let events: any[] = [];
  let allEntries: any[] = [];
  let duoId = "";

  if (
    !jsonData.hasOwnProperty("categories") ||
    !jsonData.hasOwnProperty("sites") ||
    !jsonData.hasOwnProperty("schools")
  ) {
    handleClickOpen();
    return;
  }
  let jpools = [];
  if ("judge_pools" in jsonData.categories[0]) {
    jpools = jsonData.categories[0].judge_pools;
  } else if ("jpools" in jsonData.categories[0]) {
    jpools = jsonData.categories[0].jpools;
  } else {
    //handleClickOpen();
    //return;
  }
  const debatePool = jpools.find((pool: any) => pool.name === debatePoolName);
  const iePool = jpools.find((pool: any) => pool.name === iePoolName);
  const rooms = jsonData.sites[0].rooms;
  const allJudges = jsonData.categories[0].judges;
  let clubs = jsonData.schools.map(function (club: any) {
    const uniqueFamilies = _.groupBy(
      club.students,
      (student: any) => student.last
    );
    const families = Object.entries(uniqueFamilies).map((famArray: any) => {
      return {
        name: famArray[0],
        students: famArray[1],
      };
    });

    return {
      name: club.name,
      families: families,
      entries: club.entries,
      students: club.students,
      id: club.id,
    };
  });
  let catMap: any[] = [];
  jsonData.categories.forEach((cat: any) => {
    cat.events.forEach((event: any) => {
      let type: any;
      events.push(event);
      const name = event.name.toLowerCase().trim();
      if (name.endsWith("duo")) {
        duoId = event.id;
      }
      if (event.type.toLowerCase().trim() === "debate") {
        type = EVENT_CONST.DEBATE;
      } else if (name.startsWith("jv")) {
        type = EVENT_CONST.JV;
      } else if (name.startsWith("jr")) {
        type = EVENT_CONST.JR;
      } else {
        type = EVENT_CONST.VARSITY;
      }
      catMap.push({
        id: event.id,
        type: type,
        eventName: event.name,
        eventAbbr: event.abbr,
      });
    });
  });

  clubs = clubs.filter((club: any) => club.entries && club.entries.length > 0);
  clubs = clubs.map((club: any) => {
    let judges: any[] = [];
    jsonData.categories.forEach((judgeCat: any) => {
      const temp = judgeCat.judges?.filter((judge: any) => {
        return judge.school === club.id;
      });
      judges = judges.concat(temp);
    });
    judges = judges.map((judge: any) => {
      const inDebate =
        debatePool && debatePool.judges.includes(parseInt(judge.id));
      const inIE = iePool && iePool.judges.includes(parseInt(judge.id));
      return {
        ...judge,
        inDebate,
        inIE,
      };
    });
    const c = club.families.map((family: any) => {
      let familyFee = 0;
      let familyStudents = family.students.length;
      let seperate = false;
      let famIE = 0;
      let famDebate = 0;
      const f = family.students.map((student: any) => {
        let partners = {
          debate: "",
          duo: "",
        };

        const entries = club.entries.filter((entry: any) => {
          if (entry.students.includes(student.id) && entry.active === 1) {
            if (entry.students.length > 1) {
              const p = entry.students.filter(
                (fentry: string) => fentry !== student.id
              );
              if (p.length === 1) {
                const partnerPerson = club.students.filter(
                  (stu: any) => stu.id === p[0]
                );

                let partner = { first: "", last: "" };

                if (!partnerPerson || !partnerPerson[0]) {
                  const lostObj: any = findNestedObj(clubs, "id", p[0]);
                  partner.first = lostObj?.first;
                  partner.last = lostObj?.last;
                } else {
                  partner.first = partnerPerson[0].first;
                  partner.last = partnerPerson[0].last;
                }

                if (entry.event === duoId) {
                  partners.duo = `${partner.first} ${partner.last}`;
                } else {
                  partners.debate = `${partner.first} ${partner.last}`;
                }
              }
            }
            return true;
          }
        });

        const events = entries.map((ent: any) => {
          allEntries.push(ent);
          const fromEventList = catMap.find(
            (cat: any) => cat.id.toString() === ent.event.toString()
          );
          return fromEventList;
        });
        if (true) {
          let studentEvents = speechAndDebate(events);
          if (studentEvents === "both") {
            famIE += 1;
            famDebate += 1;
          } else if (studentEvents === "ie") {
            famIE += 1;
          } else if (studentEvents === "debate") {
            famDebate += 1;
          }
        }
        if (famIE >= 2) {
          famIE = 2;
        }
        if (famDebate >= 2) {
          famDebate = 2;
        }
        let fee = calcFee(events);
        familyFee += fee;
        return {
          ...student,
          partners: partners,
          events: events,
          fee: fee,
        };
      });
      let judgeReq = 0;
      let regIE = 0;
      let regDebate = 0;
      const famJudges = judges.filter((judge: any) => {
        return judge.last === family.name;
      });

      famJudges.forEach((judge: any) => {
        if (judge.inIE) {
          regIE += 1;
        }
        if (judge.inDebate) {
          regDebate += 1;
        }
      });
      const famJudgeMap = famJudges.map(
        (famJudge: any) => `${famJudge.first} ${famJudge.last}`
      );
      return {
        ...family,
        students: f,
        fee: familyFee,
        judgeReq: judgeReq,
        judgeTotal: famJudges.length,
        judges: famJudgeMap.join(", "),
        famIE,
        famDebate,
        regIE,
        regDebate,
      };
    });

    const familyFilter = c.filter((family: any) => family.fee > 0);

    return {
      ...club,
      families: familyFilter,
    };
  });

  res = {
    clubData: clubs,
    labelData: jsonData.categories[0].events,
    events: events,
    judges: allJudges,
    allEntries: allEntries,
    rooms,
  };
  return res;
}
